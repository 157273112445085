@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress {
  width: 38px;
  height: 38px;
  line-height: 38px;
  background: 0 0;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  display: inline-block;
  border: 0
}

.progress.lg {
  width: 50px;
  height: 50px;
  line-height: 50px
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #ededed;
  position: absolute;
  top: 0;
  left: 0
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1
}

.progress .progress-left {
  left: 0
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: 0 0;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0
}

.progress[data-percentage="20"] {
  border-color: #ffb43e
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left
}

.progress .progress-right {
  right: 0
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right
}

.progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 12px;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #000;
  font-weight: 700
}

.progress .progress-value div {
  margin-top: 10px
}

.progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase
}

.progress[data-percentage^=bad] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
  border-color: red
}

.progress[data-percentage^=bad] .progress-left .progress-bar {
  animation: 0
}

.progress[data-percentage^=amberA] .progress-right .progress-bar {
  animation: loading-2-alt 1.5s linear forwards;
  border-color: #ffb43e
}

.progress[data-percentage^=amberA] .progress-left .progress-bar {
  animation: 0
}

.progress[data-percentage^=average] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
  border-color: #ffb43e
}

.progress[data-percentage^=average] .progress-left .progress-bar {
  animation: 0
}

.progress[data-percentage^=agrade] .progress-right .progress-bar {
  animation: loading-3lt 1.5s linear forwards;
  border-color: #60b636
}

.progress[data-percentage^=agrade] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
  border-color: #60b636
}

.progress[data-percentage^=green] .progress-right .progress-bar {
  animation: loading-3lt 1.5s linear forwards;
  border-color: #60b636
}

.progress[data-percentage^=green] .progress-left .progress-bar {
  animation: loading-green 1.5s linear forwards 1.5s;
  border-color: #60b636
}

@keyframes loading-1 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(80);
      transform: rotate(80deg)
  }
}

@keyframes loading-2-alt {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(130);
      transform: rotate(130deg)
  }
}

@keyframes loading-2 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(100);
      transform: rotate(100deg)
  }
}

@keyframes loading-3lt {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(180);
      transform: rotate(180deg)
  }
}

@keyframes loading-3 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(150);
      transform: rotate(150deg)
  }
}

@keyframes loading-green {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(100);
      transform: rotate(100deg)
  }
}

.rating {
  display: flex;
  font-size: 11px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.rating-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
  margin-right: 10px;
  flex-grow: 1;
}

.text-black.pl-sm {
  text-align: center;
  padding: 0 !important
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}